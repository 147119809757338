import { NavTreeItem } from '@/lib/getters/getNav';
import { normalizeLinkHref } from '@/lib/parsers/normalizeLinkHref';
import { mergePropsClassName } from '@liquorice/utils';
import Link from 'next/link';

type HeaderNavSubButtonProps = {
  toggleSubMenu: (index: number | null) => void;
  LiProps?: React.HTMLProps<HTMLLIElement>;
} & NavTreeItem;

const HeaderNavSubButton = ({ toggleSubMenu, LiProps, ...props }: HeaderNavSubButtonProps) => {
  return (
    <li key={props.id} {...mergePropsClassName(LiProps, 'font-normal')}>
      <Link
        onClick={() => toggleSubMenu(null)}
        href={normalizeLinkHref(props.nodeUri) || '#'}
        target={props.newWindow ? '_blank' : '_self'}
        className="text-m">
        {props.title}
      </Link>
    </li>
  );
};

export default HeaderNavSubButton;
