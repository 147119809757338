import { NavTreeItem } from '@/lib/getters/getNav';
import { normalizeLinkHref } from '@/lib/parsers/normalizeLinkHref';
import { LinkArrow } from '../Atoms/LinkArrow/LinkArrow';

type HeaderNavSubHeaderProps = {
  toggleSubMenu: (index: number | null) => void;
} & NavTreeItem;

const HeaderNavSubHeader = ({ toggleSubMenu, ...props }: HeaderNavSubHeaderProps) => {
  const description = props?.customAttributes?.find(
    (node) => node?.attribute == 'description',
  )?.value;

  return (
    <div className="flex w-1/3 flex-col gap-6">
      <h3>{props.title}</h3>
      <p className="max-w-80 text-base font-normal">{description || ''}</p>
      {props.nodeUri && (
        <LinkArrow onClick={() => toggleSubMenu(null)} linkHref={normalizeLinkHref(props.nodeUri)}>
          Learn More
        </LinkArrow>
      )}
    </div>
  );
};

export default HeaderNavSubHeader;
