'use client';
import { NavTreeItem } from '@/lib/getters/getNav';
import { parseLinkField } from '@/lib/parsers/linkField';
import { normalizeLinkHref } from '@/lib/parsers/normalizeLinkHref';
import Link from 'next/link';
import { usePathname } from 'next/navigation';
import { useEffect, useRef, useState } from 'react';
import { Button, NavButton } from '../Atoms/Button';
import { GenuLogo } from '../Atoms/GenuLogo';
import { DownChevron, PhoneIcon } from '../Atoms/Icons';
import { HeaderNavSubPanel } from './HeaderNavSubPanel';
import MobileNavMenu from './MobileNavMenu';
import { MobileNavMenuHeader } from './MobileNavMenuHeader';
import { SearchBarDesktop } from './SearchBarDesktop';

interface UtilitiesNavBarProps {
  phoneLink: ReturnType<typeof parseLinkField>;
  utilitiesNav: NavTreeItem[];
  homepage?: boolean;
}

const UtilitiesNavBar = ({ phoneLink, utilitiesNav, homepage }: UtilitiesNavBarProps) => {
  return (
    <ul className="mb-4 hidden justify-end gap-4 lg:flex">
      {utilitiesNav.map((item) => (
        <li key={item.id}>
          <NavButton href={normalizeLinkHref(item.nodeUri)} color={homepage ? 'darkBlue' : 'white'}>
            {item.title}
          </NavButton>
        </li>
      ))}
      <li>
        <NavButton color={homepage ? 'darkBlue' : 'white'} href={'tel:' + phoneLink?.href}>
          <PhoneIcon className={`mr-2 ${homepage ? 'fill-white' : 'fill-bgPrimary'}`} />
          {phoneLink?.children}
        </NavButton>
      </li>
    </ul>
  );
};

interface HeaderNavBarProps {
  phoneLink: ReturnType<typeof parseLinkField>;
  utilitiesNav: NavTreeItem[];
  primaryNav: NavTreeItem[];
  homepage?: boolean;
}

const HeaderNavBar = ({ phoneLink, utilitiesNav, primaryNav }: HeaderNavBarProps) => {
  const menuRef = useRef<HTMLDivElement | null>(null);
  const [activeIndex, setActiveIndex] = useState<number | null>(null);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState<boolean>(false);
  const [searchOpen, setSearchOpen] = useState<boolean>(false);
  const homepage = usePathname() === '/';

  const toggleSubMenu = (index: number | null) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (menuRef.current && !menuRef.current.contains(event.target as Node)) {
      setActiveIndex(null);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <nav className={`w-full ${homepage ? 'bg-bgPrimary' : 'bg-accent'} relative`} ref={menuRef}>
      <div className="mx-auto max-w-screen-2xl px-4 py-8 lg:px-8 lg:py-5">
        <UtilitiesNavBar phoneLink={phoneLink} utilitiesNav={utilitiesNav} homepage={homepage} />

        <div className="flex items-center justify-between">
          <Link href="/" title="Home">
            <GenuLogo
              primary={homepage ? 'white' : 'midnightBlue'}
              secondary={homepage ? 'turquoise' : 'white'}
            />
          </Link>
          <ul
            className={`hidden items-center gap-5 text-base lg:flex ${homepage ? 'text-primary' : 'text-tBase'}`}>
            {primaryNav.map((item, index) => (
              <li
                key={item.id}
                onMouseEnter={() => toggleSubMenu(index)}
                className={`text-base font-medium transition-opacity ${searchOpen ? 'opacity-0' : 'opacity-100'}`}>
                <div className="inline-flex items-center space-x-1">
                  <Link
                    href={normalizeLinkHref(item.nodeUri) || '#'}
                    target={item.newWindow ? '_blank' : '_self'}>
                    {item.title}
                  </Link>
                  {item.children.length > 0 && (
                    <DownChevron
                      className={`${homepage ? 'stroke-primary' : 'stroke-bgPrimary'} ${
                        activeIndex === index ? 'rotate-180' : ''
                      }`}
                    />
                  )}
                </div>

                {item.children.length > 0 && (
                  <HeaderNavSubPanel
                    show={activeIndex === index}
                    parentItem={item}
                    toggleSubMenu={toggleSubMenu}
                  />
                )}
              </li>
            ))}
            <li className={`${searchOpen ? 'opacity-0' : 'opacity-100'}`}>
              <Button color={homepage ? 'turquoise' : 'midnightBlue'} href="/get-involved/donate/">
                Donate
              </Button>
            </li>
            <SearchBarDesktop
              searchOpen={searchOpen}
              setSearchOpen={setSearchOpen}
              homepage={homepage}></SearchBarDesktop>
          </ul>
          <MobileNavMenuHeader
            isMobileMenuOpen={isMobileMenuOpen}
            setIsMobileMenuOpen={setIsMobileMenuOpen}
            phoneLink={phoneLink}
            homepage={homepage}
          />
        </div>
      </div>
      <MobileNavMenu
        header={
          <div className="flex items-center justify-between">
            <a href="/" title="Home">
              <GenuLogo primary={'midnightBlue'} secondary={'white'} />
            </a>
            <MobileNavMenuHeader
              isMobileMenuOpen={isMobileMenuOpen}
              setIsMobileMenuOpen={setIsMobileMenuOpen}
              phoneLink={phoneLink}
              homepage={false}
            />
          </div>
        }
        primaryNav={primaryNav}
        phoneLink={phoneLink}
        utilitiesNav={utilitiesNav}
        open={isMobileMenuOpen}
        setIsMobileMenuOpen={setIsMobileMenuOpen}
      />
    </nav>
  );
};

export default HeaderNavBar;
