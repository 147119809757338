'use client';
import { parseLinkField } from '@/lib/parsers/linkField';
import { NavButton } from '../Atoms/Button';
import { CloseIcon, HamburgerIcon, PhoneIcon } from '../Atoms/Icons';

interface MobileNavMenuHeaderProps {
  phoneLink: ReturnType<typeof parseLinkField>;
  isMobileMenuOpen: boolean;
  setIsMobileMenuOpen: (value: boolean) => void;
  homepage?: boolean;
}
export function MobileNavMenuHeader({
  phoneLink,
  setIsMobileMenuOpen,
  isMobileMenuOpen,
  homepage,
}: MobileNavMenuHeaderProps) {
  return (
    <div className="flex items-center gap-4 lg:hidden">
      <NavButton color={homepage ? 'darkBlue' : 'midnightBlue'} href={'tel:' + phoneLink?.href}>
        <PhoneIcon className={`mr-2 ${homepage ? 'fill-white' : 'fill-white'}`} />
        <span>{phoneLink?.children}</span>
      </NavButton>
      <button
        className="text-lg"
        aria-label={isMobileMenuOpen ? 'close menu' : 'open menu'}
        onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}>
        {!isMobileMenuOpen ? (
          <HamburgerIcon className={homepage ? 'stroke-bgSecondary' : 'stroke-bgPrimary'} />
        ) : (
          <CloseIcon className={homepage ? 'stroke-bgSecondary' : 'stroke-bgPrimary'} />
        )}
      </button>
    </div>
  );
}
