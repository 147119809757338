'use client';
import { NavTreeItem } from '@/lib/getters/getNav';
import { normalizeLinkHref } from '@/lib/parsers/normalizeLinkHref';
import Link from 'next/link';
import { CloseIcon } from '../Atoms/Icons';
import HeaderNavSubButton from './HeaderNavSubButton';
import HeaderNavSubHeader from './HeaderNavSubHeader';

export function HeaderNavSubPanel({
  parentItem,
  show,
  toggleSubMenu,
}: {
  parentItem: NavTreeItem;
  show: boolean;
  toggleSubMenu: (index: number | null) => void;
}) {
  const hasPassiveChild = parentItem.children.some(
    (child) => child.nodeUri === null || child.uri === null,
  );
  const hasNoSubChildren = parentItem.children.every((child) => child.children.length === 0);

  return (
    <div
      className={`absolute left-0 right-0 z-50 pt-[50px] ${show ? 'block' : 'hidden'}`}
      onMouseLeave={() => toggleSubMenu(null)}>
      <div className={`h-auto w-full bg-accent text-tBase drop-shadow-xl`}>
        <div className="relative mx-auto flex max-w-screen-2xl gap-12 px-8 py-12">
          <button className="absolute right-8 top-12" onClick={() => toggleSubMenu(null)}>
            <CloseIcon className="stroke-bgPrimary" />
          </button>
          <HeaderNavSubHeader toggleSubMenu={toggleSubMenu} {...parentItem} />
          {hasNoSubChildren || !hasPassiveChild ? (
            <ul className="mr-8 grid h-min w-2/3 grid-flow-col-dense grid-cols-3 grid-rows-4 content-start gap-3">
              {parentItem.children.map((child) => (
                <HeaderNavSubButton key={child.id} toggleSubMenu={toggleSubMenu} {...child} />
              ))}
            </ul>
          ) : (
            <ul className="mr-8 flex h-min w-2/3 flex-wrap gap-12">
              {parentItem.children.map((child) => {
                if (child.children.length > 0 && (!child.nodeUri || !child.uri)) {
                  return (
                    <li key={child.id}>
                      <div className="mb-2">
                        <Link
                          onClick={() => toggleSubMenu(null)}
                          href={normalizeLinkHref(child.nodeUri) || '#'}
                          target={child.newWindow ? '_blank' : '_self'}
                          className="text-m font-medium">
                          {child.title}
                        </Link>
                      </div>
                      <ul>
                        {child.children.map((subChild) => {
                          return (
                            <HeaderNavSubButton
                              key={subChild.id}
                              toggleSubMenu={toggleSubMenu}
                              LiProps={{ className: 'mb-2' }}
                              {...subChild}
                            />
                          );
                        })}
                      </ul>
                    </li>
                  );
                }
              })}
              {parentItem.children.length > 0 &&
                parentItem.children.some((child) => child.children.length === 0) && (
                  <li>
                    <ul>
                      {parentItem.children.map((child) => {
                        if (child.children.length === 0)
                          return (
                            <HeaderNavSubButton
                              key={child.id}
                              toggleSubMenu={toggleSubMenu}
                              LiProps={{ className: 'mb-2' }}
                              {...child}
                            />
                          );
                      })}
                    </ul>
                  </li>
                )}
            </ul>
          )}
        </div>
      </div>
    </div>
  );
}
