import(/* webpackMode: "eager" */ "/vercel/path0/frontend/node_modules/@apollo/client-react-streaming/dist/index.cc.cjs");
;
import(/* webpackMode: "eager", webpackExports: ["SimulatePreloadedQuery"] */ "/vercel/path0/frontend/node_modules/@apollo/client-react-streaming/dist/index.cc.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/frontend/node_modules/@liquorice/next-apollo/src/ApolloWrapper/ApolloWrapperInternal.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/frontend/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/frontend/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/frontend/node_modules/next/font/local/target.css?{\"path\":\"src/app/fonts/index.tsx\",\"import\":\"\",\"arguments\":[{\"variable\":\"--din-next-rounded\",\"src\":[{\"path\":\"./DINNextRounded-Regular.woff2\",\"weight\":\"400\",\"style\":\"normal\"},{\"path\":\"./DINNextRounded-Medium.woff2\",\"weight\":\"500\",\"style\":\"normal\"},{\"path\":\"./DINNextRounded-Bold.woff2\",\"weight\":\"700\",\"style\":\"normal\"}]}],\"variableName\":\"DinNextRounded\"}");
;
import(/* webpackMode: "eager" */ "/vercel/path0/frontend/src/app/globals.css");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/frontend/src/components/Atoms/Button/ScrollToTopButton.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["SearchIcon"] */ "/vercel/path0/frontend/src/components/Atoms/Icons/SearchIcon.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/frontend/src/components/Atoms/ImageAsset/ImageAsset.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/frontend/src/components/HeaderNavBar/HeaderNavBar.tsx");
