'use client';
import { useRouter } from 'next/navigation';
import React, { useEffect, useRef, useState } from 'react';
import { Button } from '../Atoms/Button';
import { CloseIcon, SearchIcon } from '../Atoms/Icons';

interface SearchBarDesktopProps {
  searchOpen: boolean;
  homepage: boolean;
  setSearchOpen: (value: boolean) => void;
}

export function SearchBarDesktop({ searchOpen, homepage, setSearchOpen }: SearchBarDesktopProps) {
  const [searchValue, setSearchValue] = useState<string>('');
  const inputRef = useRef<HTMLInputElement>(null);
  const router = useRouter();

  const submitSearch = () => {
    setSearchOpen(false);
    setSearchValue('');
    router.push(`/search/?q=${searchValue}`);
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      submitSearch();
    }
    if (e.key === 'Escape') {
      setSearchOpen(false);
    }
  };

  useEffect(() => {
    if (searchOpen && inputRef.current) {
      inputRef.current.focus();
    }
  }, [searchOpen]);

  return (
    <li className="relative">
      <div
        className={`absolute right-full top-1/2 -translate-y-1/2 ${searchOpen ? 'w-96 opacity-100' : 'w-0 opacity-0'} mx-2 flex items-center gap-4 overflow-hidden p-[1px] pt-[3px] transition-all`}>
        <input
          type="text"
          className={`h-full w-full border-b ${homepage ? 'border-b-white' : 'border-b-midnight-blue-500'} bg-transparent px-0 text-lg font-medium ${homepage ? 'text-primary placeholder-white placeholder-opacity-50' : 'placeholder-midnight-blue-500 text-tBase placeholder-opacity-50'}`}
          placeholder="Search"
          value={searchValue}
          onChange={(e) => setSearchValue(e.target.value)}
          onKeyDown={handleKeyDown}
          ref={inputRef}
          onFocus={() => setSearchOpen(true)}
        />
        <Button
          color={homepage ? 'turquoiseOutline' : 'midnightBlue'}
          element="button"
          size="circleResponsive"
          onClick={submitSearch}>
          <SearchIcon className="" />
        </Button>
      </div>
      <Button
        color={homepage ? 'turquoiseOutline' : 'midnightBlueOutline'}
        onClick={() => setSearchOpen(!searchOpen)}
        element="button"
        size="circleResponsive">
        {searchOpen ? <CloseIcon className="" /> : <SearchIcon className="" />}
      </Button>
    </li>
  );
}
